.quick-view {
  width: 100%;
}

.quick-view th:nth-child(3){
  white-space: nowrap;
  width: 1px;
}

.quick-view td:nth-child(3) > :last-child {
  float: right;
}
