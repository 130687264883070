html {
  font-size: 14px;
}

body {
  line-height: 1.42857143 !important;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

iframe {
  width: 100%;
  height: 100%;
  border: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

pre {
  white-space: normal;
}

label {
  font-weight: bold;
}

[data-lpignore]~[data-dashlanecreated] {
  display: none !important;
}
